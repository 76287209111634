<!--
 * @Description: 钱包
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:32:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-03 12:04:51
-->
<template>
  <el-card class="box">
    <el-row>
      <el-col :span="8">
        <div class="balance_label">余额（元）</div>
        <div class="balance">
          ￥
          <span class="amount">{{ model.decimal_balance }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="balance_label">待入账（元）</div>
        <div class="balance">
          ￥
          <span class="amount">{{ model.decimal_compute_amount }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="balance_label">待结算（元）</div>
        <div class="balance">
          ￥
          <span class="amount">{{ model.decimal_await_settle_amount }}</span>
        </div>
      </el-col>
    </el-row>
  </el-card>

  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="业务类型">
        <el-select
          v-model="query.type"
          placeholder="请选择"
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.text"
            :key="item.value"
            :value="item.value"
            v-for="item in enums.account_dtl_types"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类目">
        <el-select
          v-model="query.cate"
          placeholder="请选择"
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.text"
            :key="item.value"
            :value="item.value"
            v-for="item in enums.account_dtl_categories"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder="请选择"
          clearable
          style="width: 120px"
        >
          <el-option
            :label="item.text"
            :key="item.value"
            :value="item.value"
            v-for="item in enums.account_dtl_status"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收支"> 
        <el-select v-model="query.in_or_out" placeholder="请选择" clearable style="width: 100px;">
          <el-option :label="item.text" :key="item.value" :value="item.value" v-for="item in enums.in_or_out"></el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="订单号">
        <el-input
          v-model="query.order_no" clearable
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="支付流水">
        <el-input v-model="query.pay_no" placeholder="" clearable></el-input>
      </el-form-item>
      <el-form-item label="退款流水">
        <el-input v-model="query.refund_no" placeholder="" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="query.remark" placeholder="" clearable></el-input>
      </el-form-item>
      <el-form-item label="发生时间">
        <el-date-picker
          v-model="query.date_list"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"/>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <el-table-column
        label="发生时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column
        label="业务类型"
        prop="type_text"
        width="120"
      ></el-table-column>
      <el-table-column
        label="类目"
        prop="category_text"
        width="120"
      ></el-table-column>
      <el-table-column label="订单号" width="200">
        <template #default="scope">
          {{ scope.row.house_order_no }}{{ scope.row.mall_order_no }}
          <!-- <el-link :underline="false" type="primary">{{scope.row.house_order_no}}{{scope.row.mall_order_no}}</el-link> -->
        </template>
      </el-table-column>
      <el-table-column
        label="收支类型"
        prop="in_or_out_text"
        width="120"
      ></el-table-column>
      <el-table-column label="金额(元)" prop="type_text" width="120">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column
        label="支付方式"
        prop="term_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="支付流水"
        prop="pay_no"
        width="220"
      ></el-table-column>
      <el-table-column
        label="退款流水"
        prop="refund_no"
        width="220"
      ></el-table-column>
      <el-table-column label="备注" prop="remark" width="240"></el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
import common_api from "../../../http/common_api";

export default {
  data() {
    return {
      loading: false,
      model: {},
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.loadTotal();
    this.loadData();

    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
  },
  methods: {
    /**
     *
     */
    loadTotal() {
      this.$http.get("seller/v1/account/total").then((res) => {
        if (res.code == 0) {
          this.model = res.data;
        }
      });
    },

    /**
     * 加载统计
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/account/dtlRecord", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.balance_label {
  color: #909399;
}

.balance {
  font-size: 28px;
  font-weight: 600;
  margin-top: 10px;
}
</style>
